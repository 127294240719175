import { Modal } from '@/ui-kit';
import { ToolItem } from './components/tool-item';
import { SettingsData, settingsData } from './settings-data';

interface Props {
    isOpen?: boolean;
    onClose?: () => void;
    handleChangeSettingsProfile: (settings: SettingsData['action']) => void;
}

export const ModalSettingsUserChat: React.FC<Props> = ({ isOpen, onClose, handleChangeSettingsProfile }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Инструменты безопасности"
        >
            {settingsData.map((item) => {
                const IconComponent = item.icon;
                return (
                    <ToolItem
                        key={item.title}
                        title={item.title}
                        text={item.type}
                        icon={<IconComponent />}
                        onClick={() => handleChangeSettingsProfile(item.action)}
                    />
                );
            })}
        </Modal>
    );
};
