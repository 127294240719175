import React from 'react';
import styles from './footer-picker.module.scss';

interface Props {
    onClickCancel: () => void;
    onClickDone: () => void;
}

export const FooterPicker: React.FC<Props> = ({ onClickCancel, onClickDone }) => {
    return (
        <div className="datepicker-footer">
            <button
                className="cancel-button"
                onClick={onClickCancel}
                type="button"
            >
                Отмена
            </button>
            <button
                className="done-button"
                onClick={onClickDone}
                type="button"
            >
                Готово
            </button>
        </div>
    );
};
