import TextareaAutosize from 'react-textarea-autosize';
import { Button } from '@/ui-kit';
import styles from './form-message.module.scss';
import { TelegramIcon } from '@/assets/icons';
import React, { useState } from 'react';

interface Props {
    onSend?: (data: any) => void;
}
export const FormMessage: React.FC<Props> = ({ onSend }) => {
    const [message, setMessage] = useState<string>('');

    const onEnterPress = (e: any) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        handleSend();
    };

    const handleSend = () => {
        if (!message) return;

        onSend?.({
            message, // Optional
        });

        setMessage('');
    };

    return (
        <div className={styles.wrapper}>
            <form
                onSubmit={onSubmit}
                className={styles.formMessage}
            >
                <TextareaAutosize
                    onKeyDown={onEnterPress}
                    minRows={1}
                    maxRows={4}
                    className={styles.textarea}
                    placeholder="Type a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                    type="submit"
                    className={styles.button}
                >
                    <TelegramIcon
                        width={24}
                        height={24}
                    />
                </Button>
            </form>
        </div>
    );
};
