export const routesApp = {
    home: '',
    registration: '/registration/step/:stepNumer',
    browse: '/browse',
    messages: '/messages',
    chat: '/messages/chat/:id',
    liked: '/liked',
    settings: '/profile/settings',
    profile: '/profile',
    subscription: '/profile/subscription',
    profileEdit: '/profile/edit',
} as const;
