import React from 'react';
import { Range } from 'react-range';
import CustomTrack from './custom-track';
import CustomThumb from './custom-thumb';
import styles from './range-slider.module.scss';
interface Props {
    onChange: (values: number[]) => void;
    minStart?: number;
    maxStart?: number;
}

const STEP = 1;
const MIN = 0;
const MAX = 100;

export const RangeSlider: React.FC<Props> = ({ onChange, minStart = 18, maxStart = 28 }) => {
    const [values, setValues] = React.useState([minStart, maxStart]);

    const handlechange = (values: number[]) => {
        onChange(values);
    };

    return (
        <div className={styles.rangeContainer}>
            <Range
                label="Select your value"
                step={STEP}
                min={MIN}
                max={MAX}
                values={values}
                onChange={(values) => setValues(values)}
                onFinalChange={handlechange}
                renderTrack={(params) => (
                    <CustomTrack
                        params={params}
                        values={values}
                        min={MIN}
                        max={MAX}
                    />
                )}
                renderThumb={(params) => (
                    <CustomThumb
                        key={params.props.key}
                        params={params}
                    />
                )}
            />
        </div>
    );
    // return (
    //     <div className={styles.rangeContainer}>
    //         <Nouislider
    //             range={{ min: 18, max: 80 }}
    //             start={[minStart, maxStart]}
    //             connect
    //             className="range-slider"
    //             tooltips
    //             step={1}
    //             onChange={onChange}
    //             format={{
    //                 to: function (value: number) {
    //                     return Math.round(value);
    //                 },
    //                 from: function (value: number) {
    //                     return Math.round(value);
    //                 },
    //             }}
    //         />
    //     </div>
    // );
};
