import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './chat-viewport.module.scss';
import { Container, Typography } from '@/ui-kit';
// import { FormMessage, ChatMessageItem, BadgeDay, TextIsPrinted, SkeletonChatViewport } from './componentsz';
import { useActionCable, useChannel } from '@aersoftware/react-use-action-cable';
import { jwtService } from '@/services/jwt.service';
import { useGetMessagesById } from '@/hooks/use-get-messages';
import { MessageDto } from '@/types/message';
import { format } from 'date-fns';

import { ErrorMessage } from '../error-message';
import { useGetChats } from '@/hooks/use-get-chats';
import { SkeletonChatViewport } from './components/skeleton-chat-viewport';
import { BadgeDay } from './components/badge-day';
import { ChatMessageItem } from './components/chat-message-item';
import { FormMessage } from './components/form-message';
import { useActionCableCreate } from '@/hooks/use-action-cable';

interface Props {
    chatUrl?: string;
    isLoadingChat?: boolean;
    isErrorChat?: boolean;
    messagesData?: MessageDto[];
    profileId?: number | string;
}

const CHANEL_NAME = 'ChatChannel';
const isDev = process.env.NODE_ENV === 'development';
const getPathFromWebSocketUrlIsDev = (url: string) => {
    if (isDev) {
        const parsedUrl = new URL(url);
        return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
    }
    return url;
};
let chanel: any;

export const ChatViewport: React.FC<Props> = ({
    chatUrl = '',
    isLoadingChat,
    isErrorChat,
    messagesData,
    profileId,
}) => {
    const srollBottomBlockRef = useRef<null | HTMLDivElement>(null);

    const [messages, setMessages] = useState<MessageDto[]>([]);

    const [isFirstRender, setIsFirstRender] = useState(false);

    const cable = useActionCableCreate(getPathFromWebSocketUrlIsDev(chatUrl));

    useEffect(() => {
        if (isFirstRender) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
            });
        }
    }, [isFirstRender]);

    useEffect(() => {
        chanel = cable.subscriptions.create(
            {
                channel: CHANEL_NAME,
                token: jwtService.getToken(),
                profile: profileId,
            },

            {
                connected() {
                    console.log('conented');
                },
                received(data: MessageDto) {
                    setMessages((prevMessages) => {
                        const newMessage = [...prevMessages, data];
                        return newMessage;
                    });
                    if (data.is_mine) {
                        setTimeout(() => {
                            window.scrollTo({
                                top: document.documentElement.scrollHeight,
                            });
                        }, 0);
                    }
                },
            },
        );
        return () => {
            cable.subscriptions.remove(chanel);
            setIsFirstRender(false);
        };
    }, []);

    // Группировка сообщений по дате
    const groupedMessages = useMemo(
        () =>
            messages.reduce<{ date: string; messages: MessageDto[] }[]>((acc, message) => {
                const messageDate = format(new Date(message.created_at), 'yyyy-MM-dd');

                const existingGroup = acc.find((group) => group.date === messageDate);

                if (existingGroup) {
                    existingGroup.messages.push(message);
                } else {
                    acc.push({
                        date: messageDate,
                        messages: [message],
                    });
                }

                return acc;
            }, []),
        [messages],
    );

    useEffect(() => {
        if (messagesData) {
            setMessages(messagesData);
            setIsFirstRender(true);
        }
    }, [messagesData, isFirstRender]);

    if (isErrorChat) {
        return <ErrorMessage message="Ошибка получения сообщений 🙄" />;
    }

    return (
        <div className={styles.wrapper}>
            <Container className={styles.container}>
                <div className={styles.chatViewport}>
                    {isLoadingChat ? (
                        <SkeletonChatViewport />
                    ) : messages.length > 0 ? (
                        groupedMessages?.map((group, index) => (
                            <div key={group.date}>
                                <BadgeDay textDate={format(new Date(group.date), 'MMMM dd, yyyy')} />
                                {group.messages.map((message) => {
                                    return (
                                        <ChatMessageItem
                                            key={message.id}
                                            status="read"
                                            time={format(new Date(message.created_at), 'hh:mm a')}
                                            isMyMessage={message.is_mine}
                                            message={message.text}
                                        />
                                    );
                                })}
                            </div>
                        ))
                    ) : (
                        <Typography center>Список сообщений пуст</Typography>
                    )}
                    {/* <TextIsPrinted name="Светлана" /> */}
                </div>

                <div ref={srollBottomBlockRef} />
            </Container>
            <FormMessage onSend={(data) => chanel?.send(data)} />
        </div>
    );
};
