import { Button, Container, Typography } from '@/ui-kit';
import styles from './liked-list.module.scss';
import ImageMockSrc from '@/assets/images/mock/image-thumb.jpg';
import { HeartBlurIcon } from '@/assets/icons';
import { routesApp } from '@/constans/routes';
import { useAuth } from '@/store/useAuth';
import { Profile } from '@/types/profile';
import { LikedItem } from './componenets/liked-item';
import { showToast } from '@/utils/show-toast';
import { VoteProfileDto } from '@/api/profiles';
import { useVoteProfileById } from '@/hooks/use-vote-profile-by-id';

interface Props {
    profiles: Profile[];
}

export const LikedList: React.FC<Props> = ({ profiles }) => {
    const userData = useAuth((state) => state.getUser());
    const { mutateAsync: voteProfileById } = useVoteProfileById();
    const handleVoteProfileById = async (profileId: number, voteProfileDto: VoteProfileDto) => {
        try {
            await voteProfileById({
                profileId,
                voteProfileDto,
            });
            showToast('success', voteProfileDto.vote === 'like' ? 'Лайк поставлен' : 'Дизлайк поставлен');
        } catch (e) {
            showToast('error', 'Произошла ошибка');
        }
    };
    const isPaid = userData?.is_paid;
    return (
        <Container className={styles.container}>
            {!isPaid && <div className={styles.dekorHeart} />}
            {!isPaid && (
                <Typography
                    className={styles.title}
                    center
                    fontWeight="semibold"
                    fontFamily="SFProText"
                >
                    Узнай, кому ты нравишься
                </Typography>
            )}
            {!isPaid && (
                <Typography
                    center
                    className={styles.text}
                    fontWeight="normal"
                    fontFamily="SFProText"
                >
                    Смотрите анкеты тех, кому вы понравились
                    <br />и отвечайте взаимностью
                </Typography>
            )}
            {!isPaid ? (
                <div className={styles.list}>
                    {Array.from({ length: 10 }).map((_, index) => (
                        <div
                            className={styles.item}
                            key={index}
                        >
                            <HeartBlurIcon
                                width={43}
                                height={43}
                                className={styles.heart}
                            />
                            <div className={styles.image}>
                                <img
                                    src={ImageMockSrc}
                                    alt="avatar"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                profiles?.length > 0 && (
                    <div className={styles.list}>
                        {profiles?.map((profile, i) => {
                            // TODO пока прилетает дублированный текущий профиль
                            return (
                                <LikedItem
                                    img={profile.photos?.[0].thumb_url}
                                    key={profile.id + i}
                                    name={profile.public_name}
                                    onVoteProfile={(vote) => handleVoteProfileById(profile.id, { vote })}
                                />
                            );
                        })}
                    </div>
                )
            )}
            {!isPaid && (
                <Button
                    typeColor="orange"
                    variant="stretched"
                    className={styles.button}
                    isLink
                    to={routesApp.profile}
                >
                    Узнать, кто тебя лайкнул
                </Button>
            )}
        </Container>
    );
};
