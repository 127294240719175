import React, { useEffect, useState } from 'react';
import styles from './picker.module.scss';
import { ru } from 'date-fns/locale';
import { format, getMonth, setMonth } from 'date-fns';
import PickerComponent from 'react-mobile-picker';
import clsx from 'clsx';
import { FooterPicker } from '../footer-picker';
import { HeaderPicker } from '../header-picker';

interface Props {
    currentMonth?: Date | null;
    currentYear?: Date | null;
    onChange?: (value: { month: number; year: number }) => void;
    onCancelPicker: () => void;
    maxDate?: Date;
}
const formateMountName = (value: number) => {
    const date = format(setMonth(new Date(), value - 1), 'LLLL', { locale: ru });
    return date.charAt(0).toUpperCase() + date.slice(1);
};

export const Picker: React.FC<Props> = ({
    currentMonth,
    currentYear,
    onChange,
    onCancelPicker,
    maxDate = new Date(),
}) => {
    const initialMonth = currentMonth ? getMonth(currentMonth) + 1 : getMonth(new Date()) + 1;
    const initialYear = currentYear ? Number(format(currentYear, 'yyyy')) : Number(format(new Date(), 'yyyy'));

    const [month, setMonthState] = useState<number>(initialMonth);
    const [year, setYearState] = useState<number>(initialYear);

    const maxYear = maxDate.getFullYear();
    const maxMonth = maxDate.getMonth() + 1;

    const years = Array.from({ length: 100 }, (_, i) => maxYear - i).filter((year) => year >= maxYear - 100);

    const getMonths = () => {
        if (year === maxYear && maxDate) {
            return Array.from({ length: maxMonth }, (_, i) => i + 1);
        }
        return Array.from({ length: 12 }, (_, i) => i + 1);
    };

    const months = getMonths();

    useEffect(() => {
        if (year === maxYear && month > maxMonth) {
            setMonthState(maxMonth);
        }
    }, [year, maxYear, maxMonth, month]);

    const handleChange = (value: { month: number; year: number }) => {
        setMonthState(value.month);
        setYearState(value.year);
    };

    return (
        <>
            <div className={styles.wrapper}>
                {/* TODO поправить функионал пикера */}
                <HeaderPicker
                    date={currentMonth ?? new Date()}
                    onTogglePicker={onCancelPicker}
                    isYearPickerActive
                />
                <PickerComponent
                    // wheelMode="normal"
                    value={{ month, year }}
                    onChange={handleChange}
                    height={300}
                >
                    <PickerComponent.Column name="month">
                        {months.map((monthValue) => (
                            <PickerComponent.Item
                                key={monthValue}
                                value={monthValue}
                            >
                                {({ selected }) => (
                                    <div className={clsx(styles.pickerItem, selected && styles.pickerItemSelected)}>
                                        {formateMountName(monthValue)}
                                    </div>
                                )}
                            </PickerComponent.Item>
                        ))}
                    </PickerComponent.Column>
                    <PickerComponent.Column name="year">
                        {years.map((yearValue) => (
                            <PickerComponent.Item
                                key={yearValue}
                                value={yearValue}
                            >
                                {({ selected }) => (
                                    <div className={clsx(styles.pickerItem, selected && styles.pickerItemSelected)}>
                                        {yearValue}
                                    </div>
                                )}
                            </PickerComponent.Item>
                        ))}
                    </PickerComponent.Column>
                </PickerComponent>
            </div>
            <FooterPicker
                onClickCancel={onCancelPicker}
                onClickDone={() => onChange?.({ month, year })}
            />
        </>
    );
};
