import React, { useCallback, useMemo, useState } from 'react';
import styles from './form-profile-edit.module.scss';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, DataPicker, Select, Textarea, Typography, Uploader } from '@/ui-kit';
import { FieldBlock } from '../field-block';
import { dataPurposes } from '@/constans/purposes';
import { GoalCard } from '../goal-card';
import { profileEditSchema, ProfileEditSchemaType } from '@/schemes/profile-edit-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { Profile } from '@/types/profile';
import { Region, SearchParamsRegions } from '@/api/region/types';
import { useGetRegions } from '@/hooks/use-get-regions';
import { debounce } from 'lodash';
import { usePhotoUpload } from '@/hooks/use-photo-upload';
import { useDeletePhotoById } from '@/hooks/use-delete-photo-by-id';
import { useAuth } from '@/store/useAuth';
import { HeaderMenu } from '../header-menu';
import { routesApp } from '@/constans/routes';
import { useUpdateUserData } from '@/hooks/use-update-profile';
import { useNavigate } from 'react-router-dom';
import { showToast } from '@/utils/show-toast';
import { renderCity } from '@/utils/render-city';

interface Props {
    userData: Profile;
}

const DELAY_SEND_DATA = 1000;

export const FormProfileEdit: React.FC<Props> = ({ userData }) => {
    const setUserData = useAuth((state) => state.setProfile);

    const navigation = useNavigate();
    const defaultValues = useMemo(() => {
        return {
            about_me: userData?.about_me ?? null,
            travel_plans: userData?.travel_plans ?? null,
            purpose: userData?.purpose ?? 'not_specified',
            region: userData?.region ? { label: renderCity(userData.region), value: userData.region.id } : undefined,
            dob: userData?.dob ?? '',
        };
    }, [userData]);

    const [isPurposeSelected, setIsPurposeSelected] = useState<Profile['purpose']>(defaultValues.purpose);
    const [seatchParamsCities, setSeatchParamsCities] = useState<SearchParamsRegions>({ q: '' });
    const { isLoading: isLoadingCities, data: dataRegions } = useGetRegions(seatchParamsCities);
    const { mutateAsync: updateUserData } = useUpdateUserData();
    const methods = useForm<ProfileEditSchemaType>({
        resolver: zodResolver(profileEditSchema),
        defaultValues,
    });
    const { mutateAsync: uploadPhoto } = usePhotoUpload();
    const { mutateAsync: deletePhotoById } = useDeletePhotoById();

    const [photosCount, setPhotos] = useState<number>(userData?.photos?.length ?? 0);

    const handleUploadPhoto = async (file: File | null) => {
        if (!file) return;

        const formData = new FormData();
        formData.append('photo[image]', file);
        const { data } = await uploadPhoto(formData);
        setUserData({ ...userData, photos: [...(userData?.photos ?? []), data] });
        // TODO поправить photosCount
        setPhotos(photosCount + 1);
    };

    const handleDeletePhoto = async (photoId?: number) => {
        if (!photoId) return;
        await deletePhotoById(photoId);
        setPhotos(photosCount - 1);
        setUserData({ ...userData, photos: userData?.photos?.filter((photo) => photo.id !== photoId) });
    };

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        formState: { errors, isDirty },
    } = methods;

    const handleSearchCityDebounce = useCallback(
        debounce((q: string) => {
            setSeatchParamsCities({ q });
        }, DELAY_SEND_DATA),
        [seatchParamsCities],
    );

    const handleChangePurpose = (value: Profile['purpose']) => {
        setIsPurposeSelected(value);
    };

    const handleUpdateUserData = async (data: any) => {
        if (!userData) return;
        await updateUserData(data);
        reset(getValues());
        showToast('success', 'Данные профиля успешно обновлены');
        navigation(routesApp.profile);
    };
    const onSubmit = async (data: ProfileEditSchemaType) => {
        try {
            await handleUpdateUserData({
                ...data,
                region_id: data.region?.value ? data.region?.value : undefined,
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={styles.wrapper}>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.form}
                >
                    <HeaderMenu
                        title="Анкета"
                        headerBg="gray"
                        isfixed
                        append={
                            <Button
                                textColor={!isDirty ? 'gray' : 'black'}
                                className={styles.savedButton}
                                isDisabled={!isDirty}
                                type="submit"
                            >
                                Сохранить
                            </Button>
                        }
                    />
                    <div className={styles.formLabel}>
                        <Typography
                            variant="p"
                            fontWeight="semibold"
                            fontFamily="SFProText"
                            className={styles.label}
                        >
                            Мои фото
                        </Typography>
                    </div>
                    <div className={styles.uploaderBlok}>
                        {Array.from({ length: 6 }).map((_, index) => (
                            <Uploader
                                key={index}
                                onChange={handleUploadPhoto}
                                photoUrl={userData?.photos[index]?.thumb_url}
                                onDeletePhoto={() => handleDeletePhoto(userData?.photos[index]?.id)}
                            />
                        ))}
                    </div>
                    {/* <FieldBlock>
                    <Input
                        placeholder="Ваше имя"
                        label="Имя"
                        required
                    />
                </FieldBlock> */}
                    <FieldBlock>
                        <Textarea
                            label="О себе"
                            maxLength={300}
                            placeholder="Работаю в IT, люблю серфить..."
                            // defaultValue={defaultValues.about_me ?? ''}
                            {...register('about_me')}
                        />
                    </FieldBlock>
                    <div className={styles.formLabel}>
                        <Typography
                            variant="p"
                            fontWeight="semibold"
                            fontFamily="SFProText"
                            className={styles.label}
                        >
                            Цель знакомства
                        </Typography>
                    </div>
                    <div className={styles.formCardsBlock}>
                        {dataPurposes.map((purpose) => {
                            const IconComponent = purpose.illustarion;
                            return (
                                <Controller
                                    key={purpose.value}
                                    name="purpose"
                                    control={methods.control}
                                    render={({ field: { onChange } }) => (
                                        <GoalCard
                                            text={purpose.text}
                                            icon={<IconComponent />}
                                            active={purpose.value === isPurposeSelected}
                                            onChange={() => {
                                                onChange(purpose.value);
                                                handleChangePurpose(purpose.value);
                                            }}
                                        />
                                    )}
                                />
                            );
                        })}
                    </div>
                    <FieldBlock>
                        <div className={styles.formLabel}>
                            <Typography
                                variant="label"
                                fontWeight="semibold"
                            >
                                Ваши тревел мечты
                            </Typography>
                        </div>
                        <Textarea
                            maxLength={500}
                            height={98}
                            placeholder="Собираюсь зимовать на Бали, ищу компанию..."
                            // defaultValue={defaultValues.travel_plans ?? ''}
                            {...register('travel_plans')}
                        />
                    </FieldBlock>
                    <FieldBlock>
                        <div className={styles.formLabel}>
                            <Typography
                                variant="label"
                                fontWeight="medium"
                                className={styles.required}
                            >
                                Ваш город
                            </Typography>
                        </div>
                        <Select
                            name="region"
                            placeholder="Выберите город"
                            isLoading={isLoadingCities}
                            options={dataRegions?.data?.map((region: Region) => ({
                                label: renderCity(region),
                                value: region.id,
                            }))}
                            onSearch={handleSearchCityDebounce}
                        />
                    </FieldBlock>

                    {/* <FieldBlock>
                        <div className={styles.formLabel}>
                            <Typography
                                variant="label"
                                fontWeight="semibold"
                                className={styles.required}
                            >
                                Ваш пол
                            </Typography>
                        </div>
                        <div className={styles.formRadioGroup}>
                        <Radio
                            label="Мужчина"
                            value="male"
                            name={'gender'}
                        />
                        <Radio
                            label="Женщина"
                            value="female"
                            name={'gender'}
                        />
                    </div>
                    </FieldBlock> */}
                    {/* <FieldBlock>
                        <div className={styles.formLabel}>
                            <Typography
                                variant="label"
                                fontWeight="semibold"
                                className={styles.required}
                            >
                                Когда вы родились?
                            </Typography>
                        </div>

                    </FieldBlock> */}
                    <DataPicker
                        name="dob"
                        placeholder="ДД / ММ / ГГГГ"
                    />
                </form>
            </FormProvider>
        </div>
    );
};
