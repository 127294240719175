import { ArrowTopIcon, FilterIcon } from '@/assets/icons';
import { Button, Typography } from '@/ui-kit';
import styles from './card-swipe-user.module.scss';

import TinderCard from 'react-tinder-card';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonsLikeAndDislike } from '../buttons-like-and-dislike';
import { useSwipedChange } from '@/store/useSwipedChange';
import { GalleryImages } from '../gallery-images';
import { Profile } from '@/types/profile';
import { useVoteProfileById } from '@/hooks/use-vote-profile-by-id';
import clsx from 'clsx';
import { differenceInYearsResult } from '@/utils/difference-in-years-result';
import { SuccessfulRegistration } from '../successful-registration';
import { useSuccessfulMessage } from '@/store/useSuccessfulMessage';
import { LastCardQuestionnaire } from '../last-card-questionnaire';
import { CardUserInfo } from './components/card-user-info';
import { ModalProfileFilter } from '../modal-profile-filter';
import { HeaderMenu } from '../header-menu';

const ANIMATION_SLEEP_DELAY = 300;

interface Props {
    users?: Profile[];
    refetchData?: () => void;
}

type Dir = 'left' | 'right' | null;

export const CardSwiperUser: React.FC<Props> = ({ users = [], refetchData }) => {
    const { isOpen, setIsOpen } = useSuccessfulMessage((state) => state);
    const { mutateAsync: voteProfileById } = useVoteProfileById();
    const setDir = useSwipedChange((state) => state.setDir);
    const lastIndex = users.length - 1;
    const [currentSwipeSlide, setCurrentSwipeSlide] = useState<Profile>(users[lastIndex]);
    const [currentIndex, setCurrentIndex] = useState(users.length - 1);
    const [, setLastDirection] = useState<Dir>(null);
    const currentIndexRef = useRef(currentIndex);
    const [isVisibleDetails, setIsVisibleDetails] = useState(false);
    const [isFullyViewed, setIsFullyViewed] = useState(false);
    const [isModalFilterOpen, setIsModalFilterOpen] = useState(false);
    const detailsRef = useRef<HTMLDivElement>(null);

    // Создаем новый массив рефов при изменении списка пользователей
    const childRefs = useMemo(
        () =>
            Array(users.length)
                .fill(0)
                .map(() => createRef<any>()),
        [users],
    );

    const canSwipe = currentIndex >= 0;

    const handleCurrentIndex = (val: number) => {
        setCurrentIndex(val);
        currentIndexRef.current = val;
        setCurrentSwipeSlide(users[val]);
        setDir(null);
    };

    const handleChangeSwipe = (direction: Dir, index: number) => {
        setLastDirection(direction);
        handleCurrentIndex(index - 1);
    };

    const handlerOutOfFrame = async (idx: number, dir: Dir) => {
        if (idx === 0) {
            refetchData?.();
            // setIsFullyViewed(true);
            console.log('console refetch');
        }
        try {
            console.log(`Card ${idx} left the screen!`);
            setDir(null);
            await voteProfileById({
                profileId: users[idx].id,
                voteProfileDto: {
                    vote: dir === 'left' ? 'dislike' : 'like',
                },
            });
        } catch (e) {
            console.log(`error`, e);
        } finally {
            setIsVisibleDetails(false);
        }
    };

    const handelClickButton = async (dir: Dir) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(async () => {
            if (canSwipe && currentIndex < users.length) {
                await childRefs[currentIndex].current.swipe(dir);
            }
            if (isVisibleDetails) {
                setIsVisibleDetails(false);
            }
        }, ANIMATION_SLEEP_DELAY);
    };

    const hendleSrollDetails = () => {
        setIsVisibleDetails(true);
        if (detailsRef.current) {
            setTimeout(() => {
                const rect = detailsRef.current?.getBoundingClientRect();
                window.scrollTo({ top: rect?.top, behavior: 'smooth' });
            }, ANIMATION_SLEEP_DELAY);
        }
    };

    // Сброс состояния при изменении списка пользователей
    useEffect(() => {
        console.log('console useeffect');
        setCurrentIndex(lastIndex);
        if (users.length > 0) {
            setIsFullyViewed(false);
            setCurrentSwipeSlide(users[lastIndex]);
        } else {
            setIsVisibleDetails(false);
        }
    }, [users]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0 && isVisibleDetails) {
                setIsVisibleDetails(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisibleDetails]);

    const renderCards = users.map((user, index) => (
        <div
            className={styles.tinderCardWrapper}
            key={user.id}
        >
            <TinderCard
                ref={childRefs[index]}
                preventSwipe={['up', 'down']}
                className={styles.tinderCard}
                flickOnSwipe={true}
                swipeRequirementType="position"
                swipeThreshold={100}
                onSwipe={(dir) => handleChangeSwipe(dir as Dir, index)}
                onCardLeftScreen={(dir) => handlerOutOfFrame(index, dir as Dir)}
                onSwipeRequirementFulfilled={(dir) => {
                    setDir(dir as Dir);
                }}
                onSwipeRequirementUnfulfilled={() => {
                    setDir(null);
                }}
            >
                <div className={styles.wrapper}>
                    <div className={clsx(styles.card, currentIndex === index && styles.active)}>
                        <GalleryImages photos={user.photos} />
                        <div className={clsx(styles.cardInfo, isVisibleDetails && styles.visibleDetails)}>
                            <Typography
                                typeColor="white"
                                fontWeight="medium"
                                variant="h3"
                                className={styles.cardInfoText}
                            >
                                {user.public_name}
                                {differenceInYearsResult(user?.dob) > 0
                                    ? ', ' + differenceInYearsResult(user?.dob)
                                    : null}
                            </Typography>
                            <Typography
                                typeColor="white"
                                fontWeight="normal"
                                variant="p"
                                className={styles.descriptionIntro}
                            >
                                {user.about_me}
                            </Typography>
                        </div>
                    </div>
                </div>
            </TinderCard>
        </div>
    ));

    return (
        <>
            <div className={styles.container}>
                <HeaderMenu
                    title="Анкеты"
                    marginBottom={8}
                    append={
                        <Button
                            onClick={() => setIsModalFilterOpen(true)}
                            className={styles.filterButton}
                        >
                            <FilterIcon />
                        </Button>
                    }
                />

                {renderCards}

                {users.length > 0 && <div className={clsx(styles.loadMoreCard)} />}
                <LastCardQuestionnaire onClickOpenFilter={() => setIsModalFilterOpen(true)} />

                <ButtonsLikeAndDislike
                    onSwipeLeft={() => handelClickButton('left')}
                    onSwipeRight={() => handelClickButton('right')}
                    visible={!isFullyViewed && canSwipe}
                />

                <CardUserInfo
                    currentSwipeSlide={currentSwipeSlide}
                    isVisibleDetails={isVisibleDetails}
                    detailsRef={detailsRef}
                />

                {!isFullyViewed && canSwipe && (
                    <div
                        className={clsx(styles.arrowTop)}
                        onClick={hendleSrollDetails}
                    >
                        <ArrowTopIcon />
                    </div>
                )}
                {isOpen && <SuccessfulRegistration onClose={() => setIsOpen(false)} />}
                <ModalProfileFilter
                    isOpen={isModalFilterOpen}
                    onClose={() => setIsModalFilterOpen(false)}
                />
            </div>
        </>
    );
};
