import React from 'react';
import styles from './card-user-info.module.scss';
import clsx from 'clsx';
import { Container, Tag, Typography } from '@/ui-kit';
import { Profile } from '@/types/profile';
import { differenceInYearsResult } from '@/utils/difference-in-years-result';
import { LocationIcon } from '@/assets/icons';
import { Gender } from '@/constans/gender';

interface Props {
    currentSwipeSlide: Profile;
    isVisibleDetails: boolean;
    detailsRef: React.RefObject<HTMLDivElement>;
}

export const CardUserInfo: React.FC<Props> = ({ currentSwipeSlide, isVisibleDetails, detailsRef }) => {
    return (
        <div className={clsx(styles.descriptionWrapper, isVisibleDetails && styles.visibleDetails)}>
            <Container>
                <div ref={detailsRef} />
                {currentSwipeSlide?.public_name && (
                    <Typography
                        typeColor="black"
                        fontWeight="medium"
                        variant="h3"
                        className={styles.cardInfoText}
                    >
                        {currentSwipeSlide?.public_name}
                        {differenceInYearsResult(currentSwipeSlide?.dob) > 0
                            ? ', ' + differenceInYearsResult(currentSwipeSlide?.dob)
                            : null}
                    </Typography>
                )}
                {currentSwipeSlide?.region?.name && (
                    <Typography
                        typeColor="black"
                        fontWeight="medium"
                        variant="span"
                        className={clsx(styles.cardInfoText, styles.city)}
                    >
                        <LocationIcon /> {currentSwipeSlide?.region?.name}
                    </Typography>
                )}
                {currentSwipeSlide?.purpose && (
                    <Tag
                        icon={<div className={clsx(styles.tagIconNode, styles?.[`${currentSwipeSlide?.purpose}`])} />}
                        className={clsx(styles.tagInfo, styles.isIcon)}
                    >
                        Романтические отношения
                    </Tag>
                )}

                {currentSwipeSlide?.about_me && (
                    <>
                        <div className={styles.label}>
                            <Typography
                                uppercase
                                variant="span"
                                fontWeight="bold"
                            >
                                Обо мне
                            </Typography>
                        </div>

                        <Typography
                            className={styles.text}
                            variant="p"
                        >
                            {currentSwipeSlide?.about_me}
                        </Typography>
                    </>
                )}

                <div className={styles.row}>
                    {/* Добавить город */}
                    {currentSwipeSlide?.gender && (
                        <div className={styles.rowItem}>
                            <div className={styles.label}>
                                <Typography
                                    uppercase
                                    variant="span"
                                    fontWeight="bold"
                                >
                                    Пол
                                </Typography>
                            </div>

                            <Typography
                                typeColor="gray"
                                variant="p"
                                className={styles.text}
                            >
                                {Gender[currentSwipeSlide?.gender]}
                            </Typography>
                        </div>
                    )}
                </div>
                {currentSwipeSlide?.travel_plans && (
                    <>
                        <div className={styles.label}>
                            <Typography
                                uppercase
                                variant="span"
                                fontWeight="bold"
                            >
                                Мои тревел мечты
                            </Typography>
                        </div>

                        <Typography
                            className={styles.text}
                            variant="p"
                        >
                            {currentSwipeSlide?.travel_plans}
                        </Typography>
                    </>
                )}
            </Container>
        </div>
    );
};
