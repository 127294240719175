import React, { useState } from 'react';
import DatePickerComponent from 'react-datepicker';
import { DropdownIndicator } from '@/assets/icons';
import { Controller, useFormContext } from 'react-hook-form';
import { ru } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import './data-picker.scss';
import { format, getDaysInMonth, setDate, setMonth, setYear } from 'date-fns';
import 'swiper/swiper-bundle.css';
import clsx from 'clsx';
import { Typography } from '../typography';
import { HeaderPicker } from './components/header-picker';
import { Picker } from './components/picker';
import { FooterPicker } from './components/footer-picker';

const formateDateButtonLabel = (value: Date | string) => {
    return format(value === '' || !value ? new Date() : value, "dd MMMM yyyy'г.'", { locale: ru });
};

export const DataPicker: React.FC<any> = ({ name, placeholder, isError }) => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false); // Состояние открытия календаря
    const [tempDate, setTempDate] = useState<Date | null>(null); // Временная дата для выбора
    const [isPickerOpen, setIsPickerOpen] = useState(false); // Состояние для открытия слайдера
    const { control } = useFormContext();

    const handleCancel = () => {
        setTempDate(null); // Сброс временной даты при отмене
        setIsCalendarOpen(false); // Закрыть календарь
    };

    const handleDone = (onChange?: (date: string | null) => void) => {
        if (tempDate) {
            const time = tempDate as Date;
            const dateFormat = format(time, 'yyyy-MM-dd');
            onChange?.(dateFormat); // Применить выбранную дату
        }
        setIsCalendarOpen(false); // Закрыть календарь
    };
    const handleMonthYearChange = (
        value: { month: number; year: number },
        onChange?: (date: string | null) => void,
    ) => {
        let updatedDate = tempDate || new Date();
        // Обновляем месяц и год с использованием date-fns
        updatedDate = setMonth(updatedDate, value.month - 1); // Корректируем месяц
        updatedDate = setYear(updatedDate, value.year); // Обновляем год

        // Получаем количество дней в новом месяце
        const daysInNewMonth = getDaysInMonth(updatedDate);

        // Корректируем день, если он больше, чем количество дней в новом месяце
        if (updatedDate.getDate() > daysInNewMonth) {
            updatedDate = setDate(updatedDate, daysInNewMonth); // Устанавливаем последний день месяца
        }

        setTempDate(updatedDate); // Обновляем временную дату при выборе месяца и года
        const dateFormat = format(updatedDate, 'yyyy-MM-dd');
        onChange?.(dateFormat); // Применяем изменения
        setIsPickerOpen(false); // Закрыть слайдер после выбора
        setIsCalendarOpen(false); // Закрыть календарь
    };

    // ограничения для 18 лет
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    // eslint-disable-next-line no-lone-blocks
    {
        /* TODO поправить функионал пикера */
    }
    return (
        <div className="datepicker">
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value, ref } }) => {
                    return (
                        <DatePickerComponent
                            popperPlacement="top"
                            placeholderText={placeholder}
                            open={isCalendarOpen}
                            onClickOutside={() => setIsCalendarOpen(false)}
                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => {
                                const headerDate = tempDate || date;
                                return (
                                    <HeaderPicker
                                        date={headerDate}
                                        decreaseMonth={decreaseMonth}
                                        increaseMonth={increaseMonth}
                                        onTogglePicker={() => setIsPickerOpen((prev) => !prev)}
                                    />
                                );
                            }}
                            adjustDateOnChange
                            onChange={(date) => {
                                setTempDate(date as Date); // Устанавливаем временную дату
                                onChange?.(format(date as Date, 'yyyy-MM-dd'));
                            }}
                            selected={tempDate || value}
                            value={tempDate || value}
                            maxDate={eighteenYearsAgo}
                            locale={ru}
                            dateFormat="dd / MM / yyyy"
                            calendarContainer={({ children }) => {
                                return (
                                    <div
                                        className={clsx(
                                            'react-datepicker',
                                            isPickerOpen && 'react-datepicker--picker-active',
                                        )}
                                    >
                                        {!isPickerOpen && children}
                                        {isPickerOpen && (
                                            <Picker
                                                currentMonth={tempDate ?? value}
                                                currentYear={tempDate ?? value}
                                                onChange={(val) => handleMonthYearChange(val, onChange)}
                                                onCancelPicker={() => setIsPickerOpen(false)}
                                                maxDate={eighteenYearsAgo}
                                            />
                                        )}
                                        {!isPickerOpen && (
                                            <FooterPicker
                                                onClickCancel={handleCancel}
                                                onClickDone={() => handleDone(onChange)}
                                            />
                                        )}
                                    </div>
                                );
                            }}
                            onInputClick={() => setIsCalendarOpen((prev) => !prev)}
                            customInput={
                                <button
                                    className={clsx(
                                        'react-datepicker__button',
                                        isCalendarOpen && 'react-datepicker__button--active',
                                        isError && 'react-datepicker__button--error',
                                    )}
                                    type="button"
                                >
                                    <Typography
                                        fontWeight="medium"
                                        fontFamily="SFProText"
                                        className="react-datepicker__button-text"
                                    >
                                        Дата рождения
                                    </Typography>
                                    <div className="react-datepicker__button-label">
                                        <Typography
                                            fontWeight="normal"
                                            variant="span"
                                            typeColor="gray"
                                            className="react-datepicker__button-text"
                                        >
                                            {formateDateButtonLabel(tempDate ?? value)}
                                        </Typography>{' '}
                                        <DropdownIndicator />
                                    </div>
                                </button>
                            }
                        />
                    );
                }}
            />
        </div>
    );
};
