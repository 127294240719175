import { FieldError, Radio, RangeSlider, Typography } from '@/ui-kit';
import styles from './fourth-step-registartion-form.module.scss';
import { FieldBlock } from '@/components/field-block';
import { useCallback, useMemo, useState } from 'react';
import { Profile } from '@/types/profile';
import { registrationStepFourthSchema, RegistrationStepFourthType } from '@/schemes/registration-step-fourth-schema';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { debounce } from 'lodash';

import { GoalCard } from '@/components/goal-card';
import { useSuccessfulMessage } from '@/store/useSuccessfulMessage';
import { ButtonSubmitStep } from '../button-submit-step';
import { dataPurposes } from '@/constans/purposes';
interface UpdateUserData extends RegistrationStepFourthType {
    state?: Profile['state'];
}
interface Props {
    userData?: Profile | null;
    onUpdateUserData: (data: UpdateUserData) => Promise<any>;
    nextStep: () => void;
}
const DELAY_SEND_DATA = 1000;
export const FourthStepRegistartionForm: React.FC<Props> = ({ userData, onUpdateUserData, nextStep }) => {
    const { setIsOpen } = useSuccessfulMessage((state) => state);
    const initialValues = useMemo(() => {
        return {
            min_age: userData?.min_age || 18,
            max_age: userData?.max_age || 24,
            purpose: userData?.purpose ?? 'not_specified',
            looks_for: userData?.looks_for || 'any',
        };
    }, [userData]);

    const [isLoading, setIsLoading] = useState(false);
    const [isGoalSelected, setIsGoalSelected] = useState<Profile['purpose']>(initialValues.purpose);

    const methods = useForm<RegistrationStepFourthType>({
        resolver: zodResolver(registrationStepFourthSchema),
        defaultValues: initialValues,
    });
    const {
        register,
        handleSubmit,

        formState: { errors },
    } = methods;

    const onSubmit = async (data: RegistrationStepFourthType) => {
        console.log(data);

        try {
            setIsLoading(true);

            await onUpdateUserData({ state: 'complete', ...data });

            await new Promise<void>((resolve) => {
                setTimeout(() => {
                    nextStep();
                    setIsOpen(true);
                    resolve();
                }, 500);
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleChangeUserDataDebounce = useCallback(
        debounce(async (data: any) => {
            await onUpdateUserData(data);
        }, DELAY_SEND_DATA),
        [onUpdateUserData],
    );

    const handleChangeRangeAge = (value: any) => {
        methods.setValue('min_age', value[0]);
        methods.setValue('max_age', value[1]);
        handleChangeUserDataDebounce({ min_age: value[0], max_age: value[1] });
    };
    const handleChangeGoal = (value: any) => {
        setIsGoalSelected(value);
        methods.setValue('purpose', value);
        handleChangeUserDataDebounce({ purpose: value });
    };

    return (
        <FormProvider {...methods}>
            <form
                className={styles.form}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Typography
                    variant="p"
                    fontWeight="medium"
                    className={styles.titleForm}
                    center
                >
                    Кого вы ищите?
                </Typography>
                <FieldBlock>
                    <div className={styles.formLabel}>
                        <Typography
                            variant="label"
                            fontWeight="semibold"
                        >
                            Кого вы ищите?
                        </Typography>
                    </div>
                    <div className={styles.formRadioGroup}>
                        <Radio
                            label="Мужчина"
                            value="male"
                            {...register('looks_for', {
                                onChange() {
                                    handleChangeUserDataDebounce({ looks_for: 'man' });
                                },
                            })}
                        />
                        <Radio
                            label="Женщина"
                            value="female"
                            {...register('looks_for', {
                                onChange() {
                                    handleChangeUserDataDebounce({ looks_for: 'woman' });
                                },
                            })}
                        />
                        <Radio
                            label="Не важно"
                            value="any"
                            {...register('looks_for', {
                                onChange() {
                                    handleChangeUserDataDebounce({ looks_for: 'any' });
                                },
                            })}
                        />
                    </div>
                    <FieldError message={errors?.looks_for?.message} />
                </FieldBlock>
                <div className={styles.formCardsBlock}>
                    {dataPurposes.map((purpose) => {
                        const IconComponent = purpose.illustarion;
                        return (
                            <GoalCard
                                key={purpose.value}
                                text={purpose.text}
                                icon={<IconComponent />}
                                active={purpose.value === isGoalSelected}
                                onChange={() => handleChangeGoal(purpose.value)}
                            />
                        );
                    })}
                </div>
                <FieldBlock>
                    <div className={styles.formLabel}>
                        <Typography
                            variant="label"
                            fontWeight="semibold"
                        >
                            Возрастной диапазон
                        </Typography>
                    </div>
                    <RangeSlider
                        maxStart={initialValues?.max_age}
                        minStart={initialValues?.min_age}
                        onChange={handleChangeRangeAge}
                    />
                    <FieldError message={errors?.max_age?.message || errors?.min_age?.message} />
                </FieldBlock>
                <ButtonSubmitStep
                    isLoading={isLoading}
                    stepNumber={3}
                    isGray
                />
            </form>
        </FormProvider>
    );
};
