import { HeaderMenu } from '@/components/header-menu';
import { LikedList } from '@/components/liked-list';
import { useGetLikeMe } from '@/hooks/use-get-like-me';

export const Liked = () => {
    const { data } = useGetLikeMe();
    return (
        <>
            <HeaderMenu
                title="Лайки"
                marginBottom={15}
            />
            <LikedList profiles={data?.data} />
        </>
    );
};
