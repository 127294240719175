import React from 'react';
import { IRenderThumbParams } from 'react-range/lib/types';
import styles from './custom-thumb.module.scss';
import clsx from 'clsx';

type Props = {
    params: IRenderThumbParams;
};

const CustomThumb: React.FC<Props> = ({ params }) => {
    const { props, isDragged, value } = params;

    return (
        <div
            {...props}
            key={props.key}
            className={clsx(styles.thumb, isDragged && styles.isDragged)}
            style={{
                ...props.style,
            }}
        >
            <span className={styles.value}>{value}</span>
        </div>
    );
};

export default CustomThumb;
