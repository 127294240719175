import React from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import clsx from 'clsx';
import { ReactComponent as NextArrowIcon } from '@/assets/icons/calendar-next-arrow.svg';
import { ReactComponent as PrevArrowIcon } from '@/assets/icons/calendar-prev-arrow.svg';
import { ReactComponent as PrevMoreIcon } from '@/assets/icons/calendar-more-arrow.svg';
import styles from './header-picker.module.scss';

interface Props {
    date: Date;
    decreaseMonth?: VoidFunction;
    increaseMonth?: VoidFunction;
    isYearPickerActive?: boolean;
    onTogglePicker: () => void;
}

export const HeaderPicker: React.FC<Props> = ({
    date,
    decreaseMonth,
    increaseMonth,
    isYearPickerActive,
    onTogglePicker,
}) => {
    const formateDate = format(date, 'LLLL yyyy', { locale: ru });
    const labelDate = formateDate.charAt(0).toUpperCase() + formateDate.slice(1) + 'г.';

    return (
        <div className={styles.HeaderPicker}>
            <div
                className={styles.CurrentMonth}
                onClick={onTogglePicker}
            >
                {labelDate}
                <button
                    type="button"
                    className={clsx(styles.PickerArrow, isYearPickerActive && styles.active)}
                >
                    <PrevMoreIcon />
                </button>
            </div>
            <div className={clsx(styles.arrows, isYearPickerActive && styles.hide)}>
                <button
                    type="button"
                    onClick={decreaseMonth}
                >
                    <PrevArrowIcon />
                </button>
                <button
                    type="button"
                    onClick={increaseMonth}
                >
                    <NextArrowIcon />
                </button>
            </div>
        </div>
    );
};
