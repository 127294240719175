import React from 'react';
import styles from './buttons-like-dislike-card.module.scss';
import { Button } from '@/ui-kit';
import { CrossIcon, LikeFullIcon, LikeIcon } from '@/assets/icons';
import clsx from 'clsx';

interface Props {
    onClickLike: () => void;

    onClickDislike: () => void;
}

export const ButtonsLikeDislikeCard: React.FC<Props> = ({ onClickLike, onClickDislike }) => {
    return (
        <div className={styles.wrapper}>
            <Button
                className={styles.button}
                onClick={onClickDislike}
            >
                <CrossIcon
                    width={20}
                    height={20}
                />
            </Button>
            <Button
                onClick={onClickLike}
                className={clsx(styles.button, styles.right)}
            >
                <LikeIcon
                    width={20}
                    height={20}
                />
            </Button>
        </div>
    );
};
