import Router from './router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import WebApp from '@twa-dev/sdk';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

export const App = () => {
    useEffect(() => {
        WebApp.ready();
        WebApp.BackButton.isVisible = true;
        WebApp.BackButton.show();
        WebApp.expand();
    }, []);
    return (
        <QueryClientProvider client={queryClient}>
            <Router />
            <ToastContainer />
        </QueryClientProvider>
    );
};
