import { Avatar, Typography } from '@/ui-kit/';
import styles from './user-info-chat.module.scss';
import { differenceInYearsResult } from '@/utils/difference-in-years-result';

interface Props {
    status?: 'online' | 'offline';
    photo?: string;
    name?: string | null;
    ageDate?: string | null;
}

export const UserInfoChat: React.FC<Props> = ({ status = 'online', photo, name, ageDate }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.avatarWrap}>
                <Avatar
                    src={photo}
                    alt="avatar"
                    size={41}
                />
                {/* {status === 'online' && <span className={styles.status}></span>} */}
            </div>
            <div className={styles.details}>
                <Typography
                    fontWeight="bold"
                    variant="span"
                    typeColor="brown"
                >
                    {name}, {differenceInYearsResult(ageDate)}
                </Typography>
                {/* <Typography
                    fontWeight="bold"
                    variant="span"
                    typeColor="orange"
                >
                    {status}
                </Typography> */}
            </div>
        </div>
    );
};
