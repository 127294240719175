import { ApiClient } from '@/services/api.service';
import { Chat } from '@/types/chat';
import { MessageDto } from '@/types/message';
import { Profile } from '@/types/profile';
export interface VoteProfileDto {
    vote: 'like' | 'dislike';
}

interface ComplainProfileDto {
    reason: string;
}

class ProfilesApi {
    async getAll() {
        const data = await ApiClient.get<Profile[]>(`/profiles/`);
        return data;
    }
    async voteProfileById(profileId: number, voteProfileDto: VoteProfileDto) {
        const data = await ApiClient.post<any>(`/profiles/${profileId}/vote/`, voteProfileDto);
        return data;
    }
    // Убрать голос
    async unvoteProfileById(profileId: number | string) {
        const data = await ApiClient.post<any>(`/profiles/${profileId}/unvote`);
        return data;
    }
    // Удалить из пар TODO еще не готово
    async deleteProfileById(profileId: number | string) {
        const data = await ApiClient.post<any>(`/profiles/${profileId}/delete`);
        return data;
    }
    // Пожаловаться на профиль
    async complainProfileById(profileId: number | string) {
        const data = await ApiClient.post<ComplainProfileDto>(`/profiles/${profileId}/complain`);
        return data;
    }
    // Заблокировать профиль
    async blockProfileById(profileId: number | string) {
        const data = await ApiClient.post<any>(`/profiles/${profileId}/block`);
        return data;
    }
    // За кого я голосовал
    async getVotes() {
        const data = await ApiClient.get<any>(`/profile/votes`);
        return data;
    }
    // Лайкнувшие (тебуют платный профиль)
    async getLikeMe() {
        const data = await ApiClient.get<any>(`/profile/like_me`);
        return data;
    }

    async getMessagesById(profileId: number | string) {
        const data = await ApiClient.get<MessageDto[]>(`/profiles/${profileId}/messages`);
        return data;
    }
    // Мои совпадения - "НОВЫЕ ПАРЫ"
    async getMatches() {
        const data = await ApiClient.get<any>(`/profile/matches`);
        return data;
    }

    async getChats() {
        const data = await ApiClient.get<Chat[]>(`/chats`);
        return data;
    }
}

export const profilesApi = new ProfilesApi();
