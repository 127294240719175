import React from 'react';
import styles from './liked-item.module.scss';
import { ButtonsLikeDislikeCard } from '../buttons-like-dislike-card';
import { VoteProfileDto } from '@/api/profiles';

interface Props {
    img?: string | null;
    name: string | null;
    onVoteProfile: (vote: VoteProfileDto['vote']) => void;
}

export const LikedItem: React.FC<Props> = ({ img, name, onVoteProfile }) => {
    return (
        <div className={styles.wrapper}>
            <img
                src={img ?? ''}
                alt={name ?? 'profile'}
            />
            <ButtonsLikeDislikeCard
                onClickDislike={() => onVoteProfile('dislike')}
                onClickLike={() => onVoteProfile('like')}
            />
        </div>
    );
};
