import React from 'react';
import { ChatViewport } from '@/components/chat-viewport/';
import { HeaderChat } from '@/components/header-chat';
import { useGetChats } from '@/hooks/use-get-chats';
import { useGetMessagesById } from '@/hooks/use-get-messages';
import { useGetSettings } from '@/hooks/use-get-settings';
import { useCurrentChatInfo } from '@/store/useCurrentChatInfo';
import { Spiner } from '@/ui-kit';
import { useParams } from 'react-router-dom';

export const Chat: React.FC = () => {
    const { data, isLoading: isLoadingSettings } = useGetSettings();
    const { id } = useParams();
    const profileId = Number.isNaN(Number(id)) ? '' : Number(id);
    const { data: dataMessages, isError, isLoading: isLoadingMessages } = useGetMessagesById(profileId);
    const { data: chatList, isLoading: isLoadingChats, isError: isErrorChats } = useGetChats();
    const chatStore = useCurrentChatInfo((state) => state.currentChat);
    const currentChat = chatList?.data?.find((chat) => chat?.profile?.id === profileId);
    const isLoadingChat = isLoadingChats || isLoadingMessages;
    const isErrorChat = isError || isErrorChats;
    return (
        <>
            <HeaderChat
                isLoading={isLoadingChat || isErrorChat}
                profileChat={currentChat?.profile ?? chatStore}
            />
            {isLoadingSettings ? (
                <Spiner />
            ) : (
                <ChatViewport
                    profileId={profileId}
                    chatUrl={data?.data.chat_url}
                    messagesData={dataMessages?.data}
                    isErrorChat={isErrorChat}
                    isLoadingChat={isLoadingChat}
                />
            )}
        </>
    );
};
