import { profilesApi } from '@/api';
import { showToast } from '@/utils/show-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUnvoteProfileById = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: profilesApi.unvoteProfileById,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['profiles'] });
            showToast('success', 'Голос снят');
        },
        onError: (error) => {
            console.error('Error unvote profile:', error);
            showToast('error', 'Ошибка снятия голоса');
        },
    });

    return mutation;
};
