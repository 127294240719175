import { HeaderMenu } from '@/components/header-menu';
import { routesApp } from '@/constans/routes';
import styles from './messages.module.scss';

import { useGetMatches } from '@/hooks/use-get-matches';
import { useGetChats } from '@/hooks/use-get-chats';
import { useGetSettings } from '@/hooks/use-get-settings';
import { NewCouples } from '@/components/new-couples';
import { MessagesList } from '@/components/messages-list';
export function Messages() {
    const { data, isLoading: isLoadingMatches } = useGetMatches();
    const { data: chatList, isLoading: isLoadingChats } = useGetChats();
    useGetSettings();

    const isLoading = isLoadingMatches || isLoadingChats;
    const isEmptyMessage = chatList?.data?.length === 0 && data?.data?.length === 0;

    return (
        <div className={styles.messages}>
            <HeaderMenu title="Сообщения" />
            <NewCouples
                matches={data?.data}
                isLoading={isLoading}
            />
            <MessagesList
                isLoading={isLoading}
                chatList={chatList?.data}
                isEmptyMessage={isEmptyMessage}
            />
        </div>
    );
}
